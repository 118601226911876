<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Message ref="message" severity="error" :closable="true" v-if="errors.length">{{errors}}</Message>

                <Fieldset legend="Tambah Baru" :toggleable="true" :collapsed="true" class="mb-3" v-if="permission.includes('create')">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="brand">Brand</label>
                                <InputText id="brand" v-model.trim="forms.brand_name" required="true" autofocus :class="{ 'p-invalid': errorAdd.brand_name }" />
                                <small class="p-invalid" style="color: red" v-if="errorAdd.brand_name" >{{ errorAdd.brand_name[0] }}</small >
                            </div>
                        </div>
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-6">
                                <label for="nama">Deskripsi</label>
                                <Textarea v-model.trim="forms.brand_desc" required="true" rows="5" cols="30" autofocus :class="{ 'p-invalid': errorAdd.brand_desc }"/>
                                <small class="p-invalid" style="color: red" v-if="errorAdd.brand_desc" >{{ errorAdd.brand_desc[0] }}</small >
                            </div>
                        </div>
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-6">
                                <label for="image">Gambar</label>
                                <br>
                                <input type="file" ref="fileInput" id="file" name="file" accept="image/*" @change="handleFileChange" required>
                                <small class="p-invalid" style="color: red" v-if="errorAdd.file" >{{ errorAdd.file[0] }}</small >
                            </div>
                        </div>
                    </div>
                    <Button :loading="loadingAddNew" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="brand_id" @sort="onSort($event)">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="brand_name" header="Brand">
                        <template #body="slotProps">
                            {{slotProps.data.brand_name}}
                        </template>
                    </Column>
                    <Column field="brand_desc" header="Deskripsi">
                        <template #body="slotProps">
                            {{slotProps.data.brand_desc}}
                        </template>
                    </Column>
                    <Column field="is_active" header="Status">
                        <template #body="slotProps">
                            <Button icon="pi pi-check" class="p-button-rounded" v-if="slotProps.data.is_active == 'y'"/>
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger" v-else/>
                        </template>
                    </Column>
                    <Column field="gambar" header="Gambar">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.gambar">
                                <Image :src="slotProps.data.gambar" :alt="slotProps.data.gambar" width="100" preview />
                            </div>
                            <div v-else>
                                <Image src="no_image.jpg" alt="No Image" width="100" preview />
                            </div>
                        </template>
                    </Column>
					<Column header="Aksi" headerStyle="min-width:10rem;">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editItem(slotProps.data)" v-if="permission.includes('update')"/>
						</template>
					</Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

				<Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
					<div class="field">
						<label for="brand">Brand</label>
                        <InputText id="brand" v-model.trim="item.brand_name" required="true" autofocus :class="{ 'p-invalid': errorEdit.brand_name }" />
                        <small class="p-invalid" style="color: red" v-if="errorEdit.brand_name" >{{ errorEdit.brand_name[0] }}</small >
					</div>
					<div class="field">
						<label for="Deskripsi">Deskripsi</label>
                        <Textarea v-model.trim="item.brand_desc" required="true" rows="5" cols="30" autofocus :class="{ 'p-invalid': errorEdit.brand_desc }"/>
                        <small class="p-invalid" style="color: red" v-if="errorEdit.brand_desc" >{{ errorEdit.brand_desc[0] }}</small >
					</div>
                    <div class="field">
                        <label>Status</label>
                        <Dropdown v-model.trim="item.is_active" :options="dataDropdownStatus" optionLabel="name" optionValue="code" placeholder="Pilih Status" :class="{ 'p-invalid': errorEdit.status }"/>
                        <small class="p-invalid" style="color: red" v-if="errorEdit.status" >{{ errorEdit.status[0] }}</small>
                    </div>
					<div class="field">
                        <label>Gambar</label>
                        <br>
                        <input type="file" ref="fileInputEdit" id="fileEdit" name="fileEdit" accept="image/*" @change="handleFileEditChange" required>
                        <br>
                        <small class="p-invalid" style="color: black">Silahkan pilih gambar jika ingin update gambar</small >
                        <br>
                        <small class="p-invalid" style="color: red" v-if="errorEdit.file" >{{ errorEdit.file[0] }}</small >
					</div>
					<template #footer>
						<Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loadingEdit"/>
					</template>
				</Dialog>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingAddNew: false,
            loadingEdit: false,
            loadingExcel: false,
            loadingCsv: false,

            // dataDropdown
            dataDropdownStatus: [
                {name: 'AKTIF', code: 'y'},
                {name: 'TIDAK AKTIF', code: 'n'},
            ],

            // addNew
            forms: {
                brand_name: null,
                brand_desc: null,
            },
            selectedFile: null,
            selectedFileEdit: null,

            // edit
            item: {},
            editItemDialog: false,

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        this.$store.commit('setErrorAdd', {});
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
    },
    computed:{
        ...mapGetters(['errors', 'errorEdit', 'errorAdd']),
		...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        handleFileChange(event) {
            this.selectedFile = event.target.files[0];
        },
        handleFileEditChange(event) {
            this.selectedFileEdit = event.target.files[0];
        },
        // ADDNEW
        saveNew() {
            this.loadingAddNew = true;

            let formData = new FormData();
            formData.append('brand_name', this.forms.brand_name ?? '');
            formData.append('brand_desc', this.forms.brand_desc ?? '');
            formData.append('gambar', this.selectedFile ?? '');

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-brand/create',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                this.loadingAddNew = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingAddNew = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.brand_name = null;
            this.forms.brand_desc = null;
            
            this.selectedFile = null;
            // Mengambil referensi elemen input file
            const fileInput = this.$refs.fileInput;

            // Mengatur nilai elemen input file menjadi null
            fileInput.value = null;
        },
        // EDIT
		editItem(item) {
			this.item = item;

            this.selectedFileEdit = null;
			this.editItemDialog = true;
            this.$store.commit('setErrorEdit', {});

		},
		hideDialog() {
			this.editItemDialog = false;
			this.submitted = false;
		},
		updateItem() {
            this.loadingEdit = true;

            if(this.item.is_active == 'y'){
                this.item.is_active = 1;
            }else{
                this.item.is_active = 0;
            }

            let formData = new FormData();
            formData.append('brand_id', this.item.brand_id);
            formData.append('brand_name', this.item.brand_name ?? '');
            formData.append('brand_desc', this.item.brand_desc ?? '');
            formData.append('status', this.item.is_active ?? '');
            formData.append('gambar', this.selectedFileEdit ?? '');

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-brand/update',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => {
                this.loadingEdit = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Diupdate', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    this.item = {};
                    this.selectedFileEdit = null;
                    this.editItemDialog = false;
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingEdit = false;
                this.$store.commit('setErrorEdit', err.response?.data?.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Diperbarui', life: 3000,});
            });
		},
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/master-brand',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
            })
            .catch((err) => {
                console.log(err);
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/master-brand/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Master Brand Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>